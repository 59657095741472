import React from 'react'

import Title from 'components/Title'
import Paragraph from 'components/Paragraph'
import Button from 'components/Button'
import Link from 'components/Link'

const FeaturedOffersItem = ({
  title,
  image,
  description,
  mainCtaLabel,
  mainCtaLink,
  secondaryCtaLabel,
  secondaryCtaLink
}) => {
  return (
    <li className='FeaturedOffersItem'>
      <Title className='FeaturedOffersItem-title' tag='h2' textAlign='center'>{ title }</Title>
      <div className='FeaturedOffersItem-image' style={ { backgroundImage: `url(${image})` } } />
      <Paragraph textAlign='center' className='FeaturedOffersItem-description'>{ description }</Paragraph>
      {mainCtaLink && mainCtaLabel && (
        <Button to={ mainCtaLink } className='FeaturedOffersItem-button'>{ mainCtaLabel }</Button>
      )}
      <Link to={ secondaryCtaLink } className='FeaturedOffersItem-secondaryLink'>{ secondaryCtaLabel }</Link>
    </li>
  )
}

export default FeaturedOffersItem
