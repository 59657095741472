import React from 'react'
import PropTypes from 'prop-types'

import Link from 'components/Link'
import Tag from 'components/Tag'
import Paragraph from 'components/Paragraph'
import BlogPostImage from 'components/BlogPostImage'

const BlogPostItem = ({
  url = '',
  title = '',
  image = '',
  category
}) => {
  const {
    data: {
      title: categoryTitle,
      tag_color_text: categoryTagColor,
      tag_color_background: categoryTagBackgroundColor,
      filter_color_light: categoryFilterColorLight,
      filter_color_dark: categoryFilterColorDark
    }
  } = category.document

  return (
    <Link className='BlogPostItem' to={ url }>
      <div className='BlogPostItem-image'>
        <BlogPostImage
          filterColorLight={ categoryFilterColorLight }
          filterColorDark={ categoryFilterColorDark }
          src={ image }
        />
      </div>
      <div className='BlogPostItem-text'>
        <Tag style='rounded' label={ categoryTitle } className='BlogPostItem-tag' color={ categoryTagColor } backgroundColor={ categoryTagBackgroundColor } />
        <Paragraph style='small' className='BlogPostItem-title'>{ title }</Paragraph>
      </div>
    </Link>
  )
}

BlogPostItem.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  category: PropTypes.object
}

export default BlogPostItem
