import React from 'react'

import Container from 'components/Container'
import Button from 'components/Button'

import innerHTML from '../utils/innerHTML'

import mountain1 from 'assets/images/mountain-1.svg'
import mountain2 from 'assets/images/mountain-2.svg'
import mountain3 from 'assets/images/mountain-3.svg'

const OffersListText = ({
  content,
  ctaLabel,
  ctaTo
}) => {
  return (
    <section className='OffersListText'>
      <Container size='small'>
        <div className='OffersListText-text' { ...innerHTML(content) } />
        <div className='OffersListText-ctaContainer'>
          <Button to={ ctaTo }>{ ctaLabel }</Button>
        </div>
        <img className='OffersListText-mountain OffersListText-mountain--topRight' src={ mountain1 } />
        <img className='OffersListText-mountain OffersListText-mountain--bottomLeft' src={ mountain2 } />
        <img className='OffersListText-mountain OffersListText-mountain--bottomRight' src={ mountain3 } />
      </Container>
    </section>
  )
}

export default OffersListText
