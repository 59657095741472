import React, { useState } from 'react'
import Lottie from 'react-lottie'

import Title from 'components/Title'
import Paragraph from 'components/Paragraph'
import Button from 'components/Button'

const FullWidthProductsItem = ({
  color,
  title,
  firstColText,
  firstColCTALabel,
  firstColCTALink,
  secondColText,
  secondColCTALabel,
  secondColCTALink,
  icon,
  iconStyle
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: false,
    path: icon
  }

  const [isAnimationStopped, setIsAnimationStopped] = useState(true)

  return (
    <li
      className='FullWidthProductsItem'
      style={ { backgroundColor: color } }
      onMouseEnter={ () => setIsAnimationStopped(false) }
      onMouseLeave={ () => setIsAnimationStopped(true) }
    >
      <div className='FullWidthProductsItem-icon' style={ iconStyle }>
        <Lottie
          options={ defaultOptions }
          height='auto'
          width='100%'
          isStopped={ isAnimationStopped }
        />
      </div>
      <Title tag='h3' color='white' className='FullWidthProductsItem-title'>{ title }</Title>
      <div className='FullWidthProductsItem-text'>
        <div className='FullWidthProductsItem-col'>
          <Paragraph style='small' color='white'>{ firstColText }</Paragraph>
          <Button to={ firstColCTALink } style='transparent' size='small'>{ firstColCTALabel }</Button>
        </div>
        { secondColText && (
          <div className='FullWidthProductsItem-col'>
            <Paragraph style='small' color='white'>{ secondColText }</Paragraph>
            <Button to={ secondColCTALink } style='transparent' size='small'>{ secondColCTALabel }</Button>
          </div>
        )}
      </div>
    </li>
  )
}

export default FullWidthProductsItem
