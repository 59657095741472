import React from 'react'
import { useIntl } from 'react-intl'
import Container from 'components/Container'
import Paragraph from 'components/Paragraph'
import FeaturedOffersItem from 'components/FeaturedOffersItem'
import { linkResolver } from '../utils/linkResolver'

const FeaturedOffers = ({
  title,
  items
}) => {
  const slicedItems = items.slice(0, 2)
  const intl = useIntl()

  return (
    <section className='FeaturedOffers'>
      <Container size='small' className='FeaturedOffers-content'>
        <Paragraph style='bigInter' textAlign='center' className='FeaturedOffers-title'>{ title }</Paragraph>
        <ul className='FeaturedOffers-items'>
          {slicedItems.map((item, i) => {
            const data = item.offer.document.data

            return (
              <FeaturedOffersItem
                key={ `featured-offers-${i}` }
                title={ data.title.text }
                image={ item.image.url }
                description={ item.short_description.text }
                mainCtaLabel={ item.cta_label }
                mainCtaLink={ item.cta_link }
                secondaryCtaLabel={ intl.formatMessage({ id: 'cta.discover_offer' }) }
                secondaryCtaLink={ linkResolver(item.offer.document) }
              />
            )
          })}
        </ul>
      </Container>
    </section>
  )
}

export default FeaturedOffers
