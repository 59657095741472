import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'react-intl'
import Container from 'components/Container'
import Title from 'components/Title'
import Button from 'components/Button'
import BlogPostItem from 'components/BlogPostItem'
import { linkResolver } from '../utils/linkResolver'

const LatestNews = () => {
  const data = useStaticQuery(graphql`
    query LatestNewsQuery {
      allPrismicBlogPostFr: allPrismicBlogPost(sort: {fields: first_publication_date, order: DESC}, limit: 2, filter: {lang: { eq: "fr-fr" }}) {
        nodes {
          first_publication_date
          uid
          lang
          type
          data {
            title {
              text
            }
            image {
              url
            }
            category {
              document {
                ...on PrismicBlogCategory {
                  data {
                    title
                    tag_color_text
                    tag_color_background
                    filter_color_light
                    filter_color_dark
                  }
                }
              }
            }
          }
        }
      }
      allPrismicBlogPostEn: allPrismicBlogPost(sort: {fields: first_publication_date, order: DESC}, limit: 2, filter: {lang: { eq: "en-gb" }}) {
        nodes {
          first_publication_date
          uid
          lang
          type
          data {
            title {
              text
            }
            image {
              url
            }
            category {
              document {
                ...on PrismicBlogCategory {
                  data {
                    title
                    tag_color_text
                    tag_color_background
                    filter_color_light
                    filter_color_dark
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const posts = intl.locale === 'en' ? data.allPrismicBlogPostEn.nodes : data.allPrismicBlogPostFr.nodes

  return (
    <Container size='small' className='LatestNews' tag='section'>
      <div className='LatestNews-titleContainer'>
        <Title tag='h2' className='LatestNews-title'>{ intl.formatMessage({ id: 'latest_news.title' }) }</Title>
        <Button to='/actualites' style='transparentDarkBlue' className='LatestNews-button--desktop'>Toutes nos actus</Button>
      </div>
      <ul className='LatestNews-items'>
        {posts.map((post, i) => (
          <BlogPostItem
            key={ `post-${i}` }
            url={ linkResolver(post) }
            title={ post.data.title.text }
            image={ post.data.image.url }
            category={ post.data.category }
          />
        ))}
      </ul>
      <Button to='/actualites' style='transparentDarkBlue' className='LatestNews-button--mobile'>Toutes nos actus</Button>
    </Container>
  )
}

export default LatestNews
