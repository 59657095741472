import React from 'react'

import Container from 'components/Container'
import Title from 'components/Title'
import Paragraph from 'components/Paragraph'
import Button from 'components/Button'

import trees from 'assets/images/trees.svg'
import buoy from 'assets/images/buoy.svg'

const NeedHelp = ({
  title,
  text,
  ctaLabel,
  ctaTo
}) => {
  return (
    <Container size='small' className='NeedHelp' tag='section'>
      <img className='NeedHelp-buoy' src={ buoy } />
      <Title tag='h2' className='NeedHelp-title'>{ title }</Title>
      <Paragraph color='darkBlue' className='NeedHelp-text'>{ text }</Paragraph>
      <Button to={ ctaTo } style='darkBlue'>{ ctaLabel }</Button>
      <img className='NeedHelp-trees' src={ trees } />
    </Container>
  )
}

export default NeedHelp
