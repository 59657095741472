import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { useAtom } from 'jotai'
import Layout from 'components/Layout'
import Hero from 'components/Hero'
import FeaturedOffers from 'components/FeaturedOffers'
import OffersListText from 'components/OffersListText'
import NeedHelp from 'components/NeedHelp'
import FullWidthProducts from 'components/FullWidthProducts'
import LatestNews from 'components/LatestNews'
import { alternateLanguagesState } from '../atoms/languageSwitcher'

const IndexPage = ({ data }) => {
  if (!data) return null
  const document = data.prismicHomepage

  const [alternateLanguages, setAlternateLanguages] = useAtom(alternateLanguagesState)

  useEffect(() => {
    setAlternateLanguages(document.alternate_languages)
  }, [])

  return (
    <Layout pageName='HomePage'>
      <Helmet>
        <title>{ document.data.meta_title || 'Assurimo' }</title>
        <meta name='description' content={ document.data.meta_description || '' } />
      </Helmet>
      <Hero
        title={ document.data.title.text }
        subtitle={ document.data.hero_text.text }
        ctaLabel={ document.data.hero_button_label }
        ctaTo={ document.data.hero_button_link }
        background={ document.data.hero_lottie.url }
        backgroundMobile={ document.data.hero_lottie_mobile.url }
      />
      <FeaturedOffers
        title={ document.data.featured_offers_title.text }
        items={ document.data.featured_offers_items }
      />
      <OffersListText
        content={ document.data.main_text.html }
        ctaLabel={ document.data.main_text_cta_label }
        ctaTo={ document.data.main_text_cta_link }
      />
      <NeedHelp
        title={ document.data.contact_title.text }
        text={ document.data.contact_text.text }
        ctaLabel={ document.data.contact_cta_label }
        ctaTo={ document.data.contact_cta_link }
      />
      <FullWidthProducts
        title={ document.data.offers_list_title.text }
        items={ document.data.offers_list_items }
      />
      <LatestNews />
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        meta_title
        meta_description
        title {
          text
        }
        hero_text {
          text
        }
        hero_button_label 
        hero_button_link
        hero_lottie {
          url
        }
        hero_lottie_mobile {
          url
        }
        featured_offers_title {
          text
        }
        featured_offers_items {
          offer {
            document {
              ... on PrismicOffer {
                uid
                lang
                type
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
          short_description {
            text
          }
          image {
            url
          }
          cta_label
          cta_link
        }
        main_text {
          html
        }
        main_text_cta_label
        main_text_cta_link
        contact_title {
          text
        }
        contact_text {
          text
        }
        contact_cta_label
        contact_cta_link
        offers_list_title {
          text
        }
        offers_list_items {
          background_color
          item_title {
            text
          }
          text_1 {
            text
          }
          cta_label_1
          cta_link_1
          text_2 {
            text
          }
          cta_label_2
          cta_link_2
          image_position_top
          image_position_right
          image_position_bottom
          image_position_left
          image_width
          image_height
          illustration {
            url
          }
        }
      }
    }
  }
`

export default IndexPage
