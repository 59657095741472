import React from 'react'

import Container from 'components/Container'
import Title from 'components/Title'
import FullWidthProductsItem from 'components/FullWidthProductsItem'

import useWindowSize from '../utils/useWindowSize'

const FullWidthProducts = ({
  title,
  items
}) => {
  const size = useWindowSize()
  const isMobile = size.width <= 400

  const getCSSValue = (rawValue, isMobile) => {
    if (rawValue === null) {
      return 'auto'
    }

    const splitted = rawValue.split('|')

    if (isMobile && splitted.length === 2) {
      return splitted[1]
    }

    return splitted[0]
  }

  return (
    <Container size='small' className='FullWidthProducts' tag='section'>
      <Title tag='h2' className='FullWidthProducts-title' textAlign='center'>{ title }</Title>
      <ul className='FullWidthProducts-items'>
        {items.map((item, i) => {
          const iconStyle = {
            top: getCSSValue(item.image_position_top, isMobile),
            right: getCSSValue(item.image_position_right, isMobile),
            bottom: getCSSValue(item.image_position_bottom, isMobile),
            left: getCSSValue(item.image_position_left, isMobile),
            width: getCSSValue(item.image_width, isMobile),
            height: getCSSValue(item.image_height, isMobile)
          }

          return (
            <FullWidthProductsItem
              key={ `full_width_products_${i}` }
              color={ item.background_color }
              title={ item.item_title.text }
              icon={ item.illustration.url }
              iconStyle={ iconStyle }
              firstColText={ item.text_1.text }
              firstColCTALabel={ item.cta_label_1 }
              firstColCTALink={ item.cta_link_1 }
              secondColText={ item.text_2.text }
              secondColCTALabel={ item.cta_label_2 }
              secondColCTALink={ item.cta_link_2 }
            />
          )
        })}
      </ul>
    </Container>
  )
}

export default FullWidthProducts
